import React, { useState } from "react";
import clsx from "clsx";
import { ArticleCard, ColumnItem } from "@molecules";
import { Text, Container, Button } from "@atoms";

const ImpactGrid = ({ copy, tiles, className: _className }) => {
  const [limit, setLimit] = useState(4);

  return (
    <section className={clsx("relative", _className)}>
      <Container className="relative z-10 mb-16 md:mb-32">
        {copy && (
          <div className="flex flex-col items-center space-y-4 text-center">
            {copy && <Text richText>{copy}</Text>}
          </div>
        )}
        {tiles && (
          <div className={clsx("mt-12 columns-1 gap-8 space-y-8 md:columns-2")}>
            {tiles.slice(0, limit).map((tile, i) => {
              const { blockType: type, uid } = tile;
              switch (type) {
                case "card":
                  return <ArticleCard {...tile} key={uid} order={i} useColor />;
                case "statistic":
                  return (
                    <ColumnItem {...tile} key={uid} order={i} tile useColor />
                  );
                default:
                  return <div>{type}</div>;
              }
            })}
          </div>
        )}
        {tiles.length > limit && (
          <div className="mt-12 flex items-center justify-center">
            <Button color="carnation" onClick={() => setLimit(limit + 4)}>
              Load More
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

ImpactGrid.defaultProps = {};

export default ImpactGrid;
